
<template>
    <div class="bg">
        <performanceInformationCell :stationNo="stationNo" class="deatils_cell"></performanceInformationCell>
    </div>
</template>

<script>
    import performanceInformationCell from "./stationAgentsubviews/performanceInformationCell";
    import common from "../../utils/common";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        components: {
            performanceInformationCell
        },
        name: "performanceStatistics",
        data() {
            return {
                stationNo: common.getItem('stationNo')
            }
        },
        mounted() {
            uwStatisticAction('/stationagent/performanceStatistics','业绩统计明细')

        },
        methods: {
        }
    }
</script>

<style lang="less" scoped>
    .bg {
        background: #eeeeee;
        margin: 0;
        padding: 0;
        height: 100%;
        .flex {
            display: flex;
        }
        .footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 0.88rem;
            .van-button {
                width: 100%;
            }
        }
        .search {
            margin: 0 0.2rem;
            background: #eeeeee;
            padding-top: 0.2rem
        }
        .search-btn {
            color: #1989fa
        }
        .table {
            margin: 0 0.2rem 0px 0.2rem;
            background: white;
            height: 100%;
            .header {
                margin-top: 0.2rem;
                margin-left: 0.2rem;
                margin-right: 0.2rem;
                /*height: 1rem;*/
                /*line-height: 1.5rem;*/
                background: white;
                font-size: 12px;
                color: #999999;
            }
            .cell-title {
                /*width: 1.2rem;*/
                /*height: 0.5rem;*/
                /*line-height: 0.5rem;*/
                border-radius: 0.05rem;
                margin: 5px 0 ;
            }
            .cell-bottom {
                text-align: center;
                /*width: 1.2rem;*/
                height: 0.5rem;
                line-height: 0.5rem;
                /*margin-top: 0.4rem;*/
                border-radius: 0.05rem;
                color: black;
            }
        }
    }
</style>

